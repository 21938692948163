import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const TitleH2 = styled.h2<{ color?: string }>`
  text-transform: uppercase;
  font-family: ${theme.fontsFamily.montserrat.name};
  font-weight: ${theme.fontsFamily.montserrat.medium};
  font-size: 20px;
  ${{
    [theme.mediaQueries.md]: {
      fontSize: '25px',
      lineHeight: '49px',
    }
  }}
  text-align: center;
  color: ${({ color }) => color || theme.colors.black};
`;
