import styled from '@emotion/styled';
import React from 'react';
import { theme } from 'styles/theme';
import { onScroll } from 'utils/utils';
import Chevron from 'components/assets/chevron.svg';

const IconButtonScroll = styled.span<{ position?: string; bottom?: string }>`
  display: none;
  ${({ position, bottom }) => ({
    [theme.mediaQueries.md]: {
      display: 'block',
      position: position ? (position as any) : 'absolute',
      bottom: bottom || 0,
      left: position === 'absolute' || !position ? '50%' : 'unset',
      transform:
        position === 'absolute' || !position ? 'translate(-50%, 50%)' : 'unset',
      zIndex: 1,
      fontSize: '5rem',
      cursor: 'pointer',
    },
  })}
`;

const Icon = styled.i<{ iconColor?: string }>`
  & * {
    fill: ${({ iconColor }) => iconColor || theme.colors.darkOrange};
  }
  &:hover * {
    fill: ${({ iconColor }) => iconColor || theme.colors.darkOrange};
  }
`;
interface Props {
  href: string;
  scrollTo?: number;
  position?: string;
  bottom?: string;
  className?: string;
  iconColor?: string;
}

export const ScrollButton = ({
  href,
  scrollTo,
  position,
  bottom,
  className,
  iconColor,
}: Props) => {
  const handleScroll = () => {
    onScroll(href, scrollTo);
  };
  return (
    <IconButtonScroll
      className={className}
      position={position}
      bottom={bottom}
      onClick={handleScroll}
    >
      <Icon iconColor={iconColor}>
        <Chevron />
      </Icon>
    </IconButtonScroll>
  );
};
