import styled from "@emotion/styled";
import { theme } from "styles/theme";

export const Text = styled.p<{ color?: string }>`
  font-family: ${theme.fontsFamily.mulish.regular};
  font-size: 1.7rem;
  text-align: center;
  color: ${({ color }) => color || theme.colors.black};
  margin-bottom: 1rem;
`;
